<template>
  <b-container>
    <div class="d-flex justify-content-center m-3">
      <h2>註冊會員</h2>
    </div>
    <b-form>
      <b-form-group label="姓名" class="m-3">
        <b-form-input type="text" v-model="register.name" placeholder="請輸入姓名"></b-form-input>
      </b-form-group>
      <b-form-group label="公司名稱" class="m-3">
        <b-form-input type="text" v-model="register.company" placeholder="請輸入公司名稱"></b-form-input>
      </b-form-group>
      <b-form-group label="地址" class="m-3">
        <b-row class="mb-2">
          <b-col class="pr-0">
            <b-form-select v-model="register.county" :options="countylist" @change="setArea()"></b-form-select>
          </b-col>
          <b-col>
            <b-form-select v-model="register.area" :options="arealist"></b-form-select>
          </b-col>
        </b-row>
        <b-form-input type="text" v-model="register.address" placeholder="請輸入地址"></b-form-input>
      </b-form-group>
      <b-form-group label="電話" class="m-3">
        <b-form-input type="text" v-model="register.phone" placeholder="請輸入聯絡電話"></b-form-input>
      </b-form-group>
      <b-form-group label="負責業務" class="m-3">
        <b-form-select class="form-control" v-model="register.salespersonId" :options="saleslist"></b-form-select>
      </b-form-group>
      <div class="d-flex justify-content-center mt-4">
        <b-button variant="primary" @click="submit">註冊</b-button>
      </div>
    </b-form>
    <b-modal v-model="model.show" hide-header centered no-close-on-backdrop no-close-on-esc>
      <div class="w-100 d-flex justify-content-center">{{ model.text }}</div>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-center">
          <b-button variant="secondary" size="sm" @click="close"> 確認 </b-button>
        </div>
      </template>
    </b-modal>
    <b-overlay :show="loading" spinner-variant="primary" spinner-type="grow" rounded="sm" no-wrap></b-overlay>
  </b-container>
</template>

<script>
import liff from "@line/liff";
import ziplist from "../commons/zip";
export default {
  data() {
    return {
      model: {
        show: false,
        text: "",
      },
      loading: true,
      success: false,
      register: {
        name: "",
        company: "",
        county: "",
        area: "",
        address: "",
        phone: "",
        lineId: "",
        lineName: "",
        salespersonId: null,
      },
      saleslist: [{ value: null, text: "請選擇" }],
      countylist: [{ value: "", text: "請選擇" }],
      arealist: [{ value: "", text: "請選擇" }],
      liffId: "",
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      document.title = "註冊會員";
      this.countylist = [{ value: "", text: "請選擇" }, ...ziplist.map((p) => ({ value: p.countyName, text: p.countyName }))];
      await this.$http.get("/sales/list").then((res) => {
        if (res.data.success) {
          this.saleslist = [{ value: null, text: "請選擇" }, ...res.data.content.map((p) => ({ value: p.id, text: p.name }))];
        }
      });
      await this.getConfig();
      liff.init({ liffId: this.liffId }).then(() => {
        liff.getProfile().then((profile) => {
          this.register.lineId = profile.userId;
          this.register.lineName = profile.displayName;
          this.checkRegister();
        });
      });
    },
    async getConfig() {
      await this.$http.get("/configuration").then((res) => {
        this.liffId = res.data.liff_register;
      });
    },
    setArea() {
      if (this.register.county) {
        let zipArealist = ziplist.find((p) => p.countyName === this.register.county);
        this.arealist = [{ value: "", text: "請選擇" }, ...zipArealist.area.map((p) => ({ value: p.name, text: p.name }))];
      } else {
        this.arealist = [{ value: "", text: "請選擇" }];
      }
      this.register.area = "";
    },
    submit() {
      let errorMessages = [];
      if (this.register.name.replace(/(^s*)|(s*$)/g, "").length === 0) {
        errorMessages.push("姓名不得為空");
      }
      if (this.register.company.replace(/(^s*)|(s*$)/g, "").length === 0) {
        errorMessages.push("公司名稱不得為空");
      }
      if (this.register.county.replace(/(^s*)|(s*$)/g, "").length === 0 || this.register.area.replace(/(^s*)|(s*$)/g, "").length === 0 || this.register.address.replace(/(^s*)|(s*$)/g, "").length === 0) {
        errorMessages.push("地址不得為空");
      }
      if (this.register.phone.replace(/(^s*)|(s*$)/g, "").length === 0) {
        errorMessages.push("電話不得為空");
      }
      if (this.register.salespersonId == null) {
        errorMessages.push("負責業務為必選");
      }
      if (errorMessages.length > 0) {
        let h = this.$createElement;
        let messageVNode = h("div", { domProps: { innerHTML: errorMessages.join("<br />") } });
        this.$bvModal.msgBoxOk([messageVNode], {
          centered: true,
          buttonSize: "sm",
          okVariant: "danger",
          footerClass: "w-100 d-flex justify-content-center",
          okTitle: "返回",
        });
      } else {
        this.confirmSubmit();
      }
    },
    confirmSubmit() {
      this.loading = true;
      this.$http.post("/user/register", this.register).then((res) => {
        if (res.data.success) {
          this.model.text = "註冊成功！";
          this.model.show = true;
        }
        this.loading = false;
      });
    },
    checkRegister() {
      this.$http.get("/user/confirm", { params: { id: this.register.lineId } }).then((res) => {
        if (res.data.success) {
          if (res.data.content) {
            this.model.text = "您已經完成註冊";
            this.model.show = true;
          }
          this.loading = false;
        }
      });
    },
    close() {
      liff.closeWindow();
    },
  },
};
</script>